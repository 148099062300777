<template>
  <div class="main">
    <a-row>
      <a-col :span="24">
        <a-space>
          <a-radio-group :value="radioSelect" @change="radioChange">
            <a-radio-button value="today">今天</a-radio-button>
            <a-radio-button value="7days">近7天</a-radio-button>
            <a-radio-button value="30days">近30天</a-radio-button>
          </a-radio-group>
          <a-range-picker :value="selectDate" :allowClear="false" @change="RangeChange" style="width:240px">
            <a-icon slot="suffixIcon" type="calendar" :style="{color: '#333' }"/>
          </a-range-picker>
        </a-space>
      </a-col>
    </a-row>
    <a-row style="margin-top:24px;height:600px">
      <a-spin tip="Loading..." :spinning="listLoading">
      <a-col :span="19">
        <highcharts :options="chartOptions" style="height:600px"></highcharts>
      </a-col>
      <a-col :span="5">
        <div class="total">
          <a-space direction="vertical">
            <div>{{totalData.name}}</div>
            <div class="item"><span class="tip">登录人数：</span>{{totalData.login_nums}}人</div>
            <div class="item"><span class="tip">下单笔数：</span>{{totalData.order_nums}}笔</div>
            <div class="item"><span class="tip">付款笔数：</span>{{totalData.payorder_nums}}笔</div>
            <div class="item"><span class="tip">付款总额：</span>￥{{totalData.total_money / 100}}</div>
          </a-space>
        </div>
      </a-col>
      </a-spin>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment';
import { Chart } from "highcharts-vue";
export default {
  components: {
    highcharts: Chart
  },
  computed: {
    totalData() {
      let data = {name: '', login_nums: 0, order_nums: 0, payorder_nums: 0, total_money: 0}
      if(this.selectMini == ''){
        data.name = '总运营数据'
        this.data.forEach(element => {
          data.login_nums = data.login_nums+element.login_nums
          data.order_nums = data.order_nums+element.order_nums
          data.payorder_nums = data.payorder_nums+element.payorder_nums
          data.total_money = data.total_money+element.total_money
        });
      }else{
        this.data.forEach(element => {
          if(this.selectMini == element.name){
            data = {name: element.name + ' 运营数据', login_nums: element.login_nums, order_nums: element.order_nums, payorder_nums: element.payorder_nums, total_money: element.total_money}
          }
        });
      }
      return data
    },
  },
  data() {
    return {
      radioSelect: '',
      selectDate: [moment('null', 'YYYY-MM-DD'), moment('null', 'YYYY-MM-DD')],
      data: [],
      listLoading: false,
      selectMini:'',
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          //text: moment(this.selectDate[0]).format('yyyy-MM-DD') + " ~ "+ moment(this.selectDate[1]).format('yyyy-MM-DD') + " 运营数据统计"
          text: "运营数据统计"
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y} 元</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y} 元',
              // style: {
              //   color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              // }
            },
            point:{
              events:{
                click: (e)=> {
                  setTimeout(()=>{
                    if(e.point.options.selected){
                      this.selectMini = e.point.name
                      
                    }else{
                      this.selectMini = ''
                    } 
                  },300) 
                }
              }
            }
          }
        },
        series: [{
          name: '付款总额',
          colorByPoint: true,
          size:'400px',
          data: []
        }]
      }
    };
  },
  beforeMount(){
    this.radioSelect = 'today'
    const today = moment(new Date()).add('year',0).format("YYYY-MM-DD")
    this.selectDate = [moment(today, 'YYYY-MM-DD'), moment(today, 'YYYY-MM-DD')]
  },
  mounted() {
    this.getStatData();
  },
  methods: {
    // 统计数据
    getStatData(){
      this.listLoading = true
      this.chartOptions.series[0].data = []
      this.$http
        .get("/service/stat/total",{params: {
          start: moment(this.selectDate[0]).format('yyyy-MM-DD'), 
          end: moment(this.selectDate[1]).format('yyyy-MM-DD')
        }})
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result
          res.data.result.forEach(element => {
            this.chartOptions.series[0].data.push({name: element.name, y: element.total_money / 100});
          });
          //chart.series[0].addPoint((50 * (i % 3)) + 10);
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    //单选change
    radioChange(e){
      this.radioSelect = e.target.value
      const end = moment(moment(new Date()).add('year',0).format("YYYY-MM-DD"), 'YYYY-MM-DD')
      let start = null
      if(e.target.value == 'today'){
        start = end
      }else if(e.target.value == '7days'){
        start = moment(moment().subtract(6 ,'days').format('YYYY-MM-DD'), 'YYYY-MM-DD')
      }else{
        start = moment(moment().subtract(29 ,'days').format('YYYY-MM-DD'), 'YYYY-MM-DD')
      }
      this.selectDate = [start, end]
      //接下来请求数据 todo
      this.getStatData()
    },
    //日期change
    RangeChange(date, dateString) {
      this.radioSelect = ''
      this.selectDate = [moment(dateString[0], 'YYYY-MM-DD'), moment(dateString[1], 'YYYY-MM-DD')]
      this.getStatData()
      //接下来请求数据 todo
    },
  }
};
</script>